@import "../_constants.scss";

.page {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .wrap {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;

    .item {
      position: relative;
      border: $border-size solid $border-color;
      cursor: pointer;
      margin-bottom: 15px;
      margin-right: 15px;
      width: $icon-size;
      height: $icon-size;

      &:hover {
        border: $border-size solid $border-color-active;

        .count {
          display: block;
        }
      }

      &:last-child {
        margin-right: 0;
      }

      .logo {
        width: $icon-size;
        height: $icon-size;
      }

      .count {
        display: none;
        position: absolute;
        bottom: 3px;
        right: 3px;
        background-color: $primary;
        border-radius: 6px;
        padding: 3px 6px;
        font-size: 0.6em;
        font-weight: 700;
      }
    }
  }
}
